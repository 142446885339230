import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

function i(Image) {
  return props => (
    <StaticQuery query={graphql`
        query {
          TrebleWeb01: file(relativePath: { eq: "TrebleWeb01.jpg" }) {...fluidImage}
          TrebleWeb02: file(relativePath: { eq: "TrebleWeb02.jpg" }) {...fluidImage}
          TrebleWeb03: file(relativePath: { eq: "TrebleWeb03.jpg" }) {...fluidImage}
          TrebleWeb04: file(relativePath: { eq: "TrebleWeb04.jpg" }) {...fluidImage}
          TrebleWeb05: file(relativePath: { eq: "TrebleWeb05.jpg" }) {...fluidImage}
          TrebleWeb06: file(relativePath: { eq: "TrebleWeb06.jpg" }) {...fluidImage}
          TrebleWeb07: file(relativePath: { eq: "TrebleWeb07.jpg" }) {...fluidImage}
          TrebleWeb08: file(relativePath: { eq: "TrebleWeb08.jpg" }) {...fluidImage}
          TrebleWeb09: file(relativePath: { eq: "TrebleWeb09.jpg" }) {...fluidImage}
          TrebleWeb10: file(relativePath: { eq: "TrebleWeb10.jpg" }) {...fluidImage}
          TrebleWeb11: file(relativePath: { eq: "TrebleWeb11.jpg" }) {...fluidImage}
          TrebleWeb12: file(relativePath: { eq: "TrebleWeb12.jpg" }) {...fluidImage}
          TrebleWeb13: file(relativePath: { eq: "TrebleWeb13.jpg" }) {...fluidImage}
          TrebleWeb14: file(relativePath: { eq: "TrebleWeb14.jpg" }) {...fluidImage}
          TrebleWeb15: file(relativePath: { eq: "TrebleWeb15.jpg" }) {...fluidImage}
          TrebleWeb16: file(relativePath: { eq: "TrebleWeb16.jpg" }) {...fluidImage}
          TrebleWeb17: file(relativePath: { eq: "TrebleWeb17.jpg" }) {...fluidImage}
          TrebleWeb18: file(relativePath: { eq: "TrebleWeb18.jpg" }) {...fluidImage}
          TrebleWeb19: file(relativePath: { eq: "TrebleWeb19.jpg" }) {...fluidImage}
          TrebleWebsite01: file(relativePath: { eq: "TrebleWebsite01.jpg" }) {...fluidImage}
          TrebleWebsite02: file(relativePath: { eq: "TrebleWebsite02.jpg" }) {...fluidImage}
          TrebleWebsite03: file(relativePath: { eq: "TrebleWebsite03.jpg" }) {...fluidImage}
          TrebleWebsite04: file(relativePath: { eq: "TrebleWebsite04.jpg" }) {...fluidImage}
          TrebleWebsite05: file(relativePath: { eq: "TrebleWebsite05.jpg" }) {...fluidImage}
          TrebleMobile01: file(relativePath: { eq: "TrebleMobile01.jpg" }) {...fluidImage}
          TrebleMobile02: file(relativePath: { eq: "TrebleMobile02.jpg" }) {...fluidImage}
          TrebleMobile03: file(relativePath: { eq: "TrebleMobile03.jpg" }) {...fluidImage}
          TrebleMobile04: file(relativePath: { eq: "TrebleMobile04.jpg" }) {...fluidImage}
          TrebleMobile05: file(relativePath: { eq: "TrebleMobile05.jpg" }) {...fluidImage}
          TrebleMobile06: file(relativePath: { eq: "TrebleMobile06.jpg" }) {...fluidImage}
          TrebleMobile07: file(relativePath: { eq: "TrebleMobile07.jpg" }) {...fluidImage}
          TrebleMobile08: file(relativePath: { eq: "TrebleMobile08.jpg" }) {...fluidImage}
          TrebleMobile09: file(relativePath: { eq: "TrebleMobile09.jpg" }) {...fluidImage}
          TrebleIndustries01: file(relativePath: { eq: "TrebleIndustries01.jpg" }) {...fluidImage}
          TrebleIndustries02: file(relativePath: { eq: "TrebleIndustries02.jpg" }) {...fluidImage}
          TrebleIndustries03: file(relativePath: { eq: "TrebleIndustries03.jpg" }) {...fluidImage}
          TrebleIndustries04: file(relativePath: { eq: "TrebleIndustries04.jpg" }) {...fluidImage}
          TreblePro01: file(relativePath: { eq: "TreblePro01.jpg" }) {...fluidImage}
          TreblePro02: file(relativePath: { eq: "TreblePro02.jpg" }) {...fluidImage}
          TreblePro03: file(relativePath: { eq: "TreblePro03.jpg" }) {...fluidImage}
          TreblePro04: file(relativePath: { eq: "TreblePro04.jpg" }) {...fluidImage}
          Tangerine01: file(relativePath: { eq: "Tangerine01.jpg" }) {...fluidImage}
          Tangerine02: file(relativePath: { eq: "Tangerine02.jpg" }) {...fluidImage}
          Tangerine03: file(relativePath: { eq: "Tangerine03.jpg" }) {...fluidImage}
          Tangerine04: file(relativePath: { eq: "Tangerine04.jpg" }) {...fluidImage}
          WoosterWebsite01: file(relativePath: { eq: "WoosterWebsite01.jpg" }) {...fluidImage}
          WoosterWebsite02: file(relativePath: { eq: "WoosterWebsite02.jpg" }) {...fluidImage}
          WoosterWebsite03: file(relativePath: { eq: "WoosterWebsite03.jpg" }) {...fluidImage}
          WoosterApp01: file(relativePath: { eq: "WoosterApp01.jpg" }) {...fluidImage}
          WoosterApp02: file(relativePath: { eq: "WoosterApp02.jpg" }) {...fluidImage}
          WoosterApp03: file(relativePath: { eq: "WoosterApp03.jpg" }) {...fluidImage}
          WoosterApp04: file(relativePath: { eq: "WoosterApp04.jpg" }) {...fluidImage}
          WoosterApp05: file(relativePath: { eq: "WoosterApp05.jpg" }) {...fluidImage}
          WoosterApp06: file(relativePath: { eq: "WoosterApp06.jpg" }) {...fluidImage}
          WoosterApp07: file(relativePath: { eq: "WoosterApp07.jpg" }) {...fluidImage}
          WoosterApp08: file(relativePath: { eq: "WoosterApp08.jpg" }) {...fluidImage}
          WoosterApp09: file(relativePath: { eq: "WoosterApp09.jpg" }) {...fluidImage}
          WoosterApp10: file(relativePath: { eq: "WoosterApp10.jpg" }) {...fluidImage}
          WoosterApp11: file(relativePath: { eq: "WoosterApp11.jpg" }) {...fluidImage}
          WoosterApp12: file(relativePath: { eq: "WoosterApp12.jpg" }) {...fluidImage}
          WoosterApp13: file(relativePath: { eq: "WoosterApp13.jpg" }) {...fluidImage}
          WoosterApp14: file(relativePath: { eq: "WoosterApp14.jpg" }) {...fluidImage}
          Archive01: file(relativePath: { eq: "Archive01.jpg" }) {...fluidImage}
          Archive02: file(relativePath: { eq: "Archive02.jpg" }) {...fluidImage}
          Archive03: file(relativePath: { eq: "Archive03.jpg" }) {...fluidImage}
          Archive04: file(relativePath: { eq: "Archive04.jpg" }) {...fluidImage}
          Archive05: file(relativePath: { eq: "Archive05.jpg" }) {...fluidImage}
          Archive06: file(relativePath: { eq: "Archive06.jpg" }) {...fluidImage}
          Archive07: file(relativePath: { eq: "Archive07.jpg" }) {...fluidImage}
          Archive08: file(relativePath: { eq: "Archive08.jpg" }) {...fluidImage}
          Archive09: file(relativePath: { eq: "Archive09.jpg" }) {...fluidImage}
          Archive10: file(relativePath: { eq: "Archive10.jpg" }) {...fluidImage}
          Archive11: file(relativePath: { eq: "Archive11.jpg" }) {...fluidImage}
          Archive12: file(relativePath: { eq: "Archive12.jpg" }) {...fluidImage}
          Archive13: file(relativePath: { eq: "Archive13.jpg" }) {...fluidImage}
          Archive14: file(relativePath: { eq: "Archive14.jpg" }) {...fluidImage}
          Archive15: file(relativePath: { eq: "Archive15.jpg" }) {...fluidImage}
          Archive16: file(relativePath: { eq: "Archive16.jpg" }) {...fluidImage}
        }`}
        render={data => <Image {...props} data={data} />}
    />
  )
}

export const TrebleWeb01 = i(props => <Img fluid={props.data.TrebleWeb01.childImageSharp.fluid} className={props.className} />)
export const TrebleWeb02 = i(props => <Img fluid={props.data.TrebleWeb02.childImageSharp.fluid} className={props.className} />)
export const TrebleWeb03 = i(props => <Img fluid={props.data.TrebleWeb03.childImageSharp.fluid} className={props.className} />)
export const TrebleWeb04 = i(props => <Img fluid={props.data.TrebleWeb04.childImageSharp.fluid} className={props.className} />)
export const TrebleWeb05 = i(props => <Img fluid={props.data.TrebleWeb05.childImageSharp.fluid} className={props.className} />)
export const TrebleWeb06 = i(props => <Img fluid={props.data.TrebleWeb06.childImageSharp.fluid} className={props.className} />)
export const TrebleWeb07 = i(props => <Img fluid={props.data.TrebleWeb07.childImageSharp.fluid} className={props.className} />)
export const TrebleWeb08 = i(props => <Img fluid={props.data.TrebleWeb08.childImageSharp.fluid} className={props.className} />)
export const TrebleWeb09 = i(props => <Img fluid={props.data.TrebleWeb09.childImageSharp.fluid} className={props.className} />)
export const TrebleWeb10 = i(props => <Img fluid={props.data.TrebleWeb10.childImageSharp.fluid} className={props.className} />)
export const TrebleWeb11 = i(props => <Img fluid={props.data.TrebleWeb11.childImageSharp.fluid} className={props.className} />)
export const TrebleWeb12 = i(props => <Img fluid={props.data.TrebleWeb12.childImageSharp.fluid} className={props.className} />)
export const TrebleWeb13 = i(props => <Img fluid={props.data.TrebleWeb13.childImageSharp.fluid} className={props.className} />)
export const TrebleWeb14 = i(props => <Img fluid={props.data.TrebleWeb14.childImageSharp.fluid} className={props.className} />)
export const TrebleWeb15 = i(props => <Img fluid={props.data.TrebleWeb15.childImageSharp.fluid} className={props.className} />)
export const TrebleWeb16 = i(props => <Img fluid={props.data.TrebleWeb16.childImageSharp.fluid} className={props.className} />)
export const TrebleWeb17 = i(props => <Img fluid={props.data.TrebleWeb17.childImageSharp.fluid} className={props.className} />)
export const TrebleWeb18 = i(props => <Img fluid={props.data.TrebleWeb18.childImageSharp.fluid} className={props.className} />)
export const TrebleWeb19 = i(props => <Img fluid={props.data.TrebleWeb19.childImageSharp.fluid} className={props.className} />)
export const TrebleWebsite01 = i(props => <Img fluid={props.data.TrebleWebsite01.childImageSharp.fluid} className={props.className} />)
export const TrebleWebsite02 = i(props => <Img fluid={props.data.TrebleWebsite02.childImageSharp.fluid} className={props.className} />)
export const TrebleWebsite03 = i(props => <Img fluid={props.data.TrebleWebsite03.childImageSharp.fluid} className={props.className} />)
export const TrebleWebsite04 = i(props => <Img fluid={props.data.TrebleWebsite04.childImageSharp.fluid} className={props.className} />)
export const TrebleWebsite05 = i(props => <Img fluid={props.data.TrebleWebsite05.childImageSharp.fluid} className={props.className} />)
export const TrebleMobile01 = i(props => <Img fluid={props.data.TrebleMobile01.childImageSharp.fluid} className={props.className} />)
export const TrebleMobile02 = i(props => <Img fluid={props.data.TrebleMobile02.childImageSharp.fluid} className={props.className} />)
export const TrebleMobile03 = i(props => <Img fluid={props.data.TrebleMobile03.childImageSharp.fluid} className={props.className} />)
export const TrebleMobile04 = i(props => <Img fluid={props.data.TrebleMobile04.childImageSharp.fluid} className={props.className} />)
export const TrebleMobile05 = i(props => <Img fluid={props.data.TrebleMobile05.childImageSharp.fluid} className={props.className} />)
export const TrebleMobile06 = i(props => <Img fluid={props.data.TrebleMobile06.childImageSharp.fluid} className={props.className} />)
export const TrebleMobile07 = i(props => <Img fluid={props.data.TrebleMobile07.childImageSharp.fluid} className={props.className} />)
export const TrebleMobile08 = i(props => <Img fluid={props.data.TrebleMobile08.childImageSharp.fluid} className={props.className} />)
export const TrebleMobile09 = i(props => <Img fluid={props.data.TrebleMobile09.childImageSharp.fluid} className={props.className} />)
export const TrebleIndustries01 = i(props => <Img fluid={props.data.TrebleIndustries01.childImageSharp.fluid} className={props.className} />)
export const TrebleIndustries02 = i(props => <Img fluid={props.data.TrebleIndustries02.childImageSharp.fluid} className={props.className} />)
export const TrebleIndustries03 = i(props => <Img fluid={props.data.TrebleIndustries03.childImageSharp.fluid} className={props.className} />)
export const TrebleIndustries04 = i(props => <Img fluid={props.data.TrebleIndustries04.childImageSharp.fluid} className={props.className} />)
export const TreblePro01 = i(props => <Img fluid={props.data.TreblePro01.childImageSharp.fluid} className={props.className} />)
export const TreblePro02 = i(props => <Img fluid={props.data.TreblePro02.childImageSharp.fluid} className={props.className} />)
export const TreblePro03 = i(props => <Img fluid={props.data.TreblePro03.childImageSharp.fluid} className={props.className} />)
export const TreblePro04 = i(props => <Img fluid={props.data.TreblePro04.childImageSharp.fluid} className={props.className} />)
export const Tangerine01 = i(props => <Img fluid={props.data.Tangerine01.childImageSharp.fluid} className={props.className} />)
export const Tangerine02 = i(props => <Img fluid={props.data.Tangerine02.childImageSharp.fluid} className={props.className} />)
export const Tangerine03 = i(props => <Img fluid={props.data.Tangerine03.childImageSharp.fluid} className={props.className} />)
export const Tangerine04 = i(props => <Img fluid={props.data.Tangerine04.childImageSharp.fluid} className={props.className} />)
export const WoosterWebsite01 = i(props => <Img fluid={props.data.WoosterWebsite01.childImageSharp.fluid} className={props.className} />)
export const WoosterWebsite02 = i(props => <Img fluid={props.data.WoosterWebsite02.childImageSharp.fluid} className={props.className} />)
export const WoosterWebsite03 = i(props => <Img fluid={props.data.WoosterWebsite03.childImageSharp.fluid} className={props.className} />)
export const WoosterApp01 = i(props => <Img fluid={props.data.WoosterApp01.childImageSharp.fluid} className={props.className} />)
export const WoosterApp02 = i(props => <Img fluid={props.data.WoosterApp02.childImageSharp.fluid} className={props.className} />)
export const WoosterApp03 = i(props => <Img fluid={props.data.WoosterApp03.childImageSharp.fluid} className={props.className} />)
export const WoosterApp04 = i(props => <Img fluid={props.data.WoosterApp04.childImageSharp.fluid} className={props.className} />)
export const WoosterApp05 = i(props => <Img fluid={props.data.WoosterApp05.childImageSharp.fluid} className={props.className} />)
export const WoosterApp06 = i(props => <Img fluid={props.data.WoosterApp06.childImageSharp.fluid} className={props.className} />)
export const WoosterApp07 = i(props => <Img fluid={props.data.WoosterApp07.childImageSharp.fluid} className={props.className} />)
export const WoosterApp08 = i(props => <Img fluid={props.data.WoosterApp08.childImageSharp.fluid} className={props.className} />)
export const WoosterApp09 = i(props => <Img fluid={props.data.WoosterApp09.childImageSharp.fluid} className={props.className} />)
export const WoosterApp10 = i(props => <Img fluid={props.data.WoosterApp10.childImageSharp.fluid} className={props.className} />)
export const WoosterApp11 = i(props => <Img fluid={props.data.WoosterApp11.childImageSharp.fluid} className={props.className} />)
export const WoosterApp12 = i(props => <Img fluid={props.data.WoosterApp12.childImageSharp.fluid} className={props.className} />)
export const WoosterApp13 = i(props => <Img fluid={props.data.WoosterApp13.childImageSharp.fluid} className={props.className} />)
export const WoosterApp14 = i(props => <Img fluid={props.data.WoosterApp14.childImageSharp.fluid} className={props.className} />)
export const Archive01 = i(props => <Img fluid={props.data.Archive01.childImageSharp.fluid} className={props.className} />)
export const Archive02 = i(props => <Img fluid={props.data.Archive02.childImageSharp.fluid} className={props.className} />)
export const Archive03 = i(props => <Img fluid={props.data.Archive03.childImageSharp.fluid} className={props.className} />)
export const Archive04 = i(props => <Img fluid={props.data.Archive04.childImageSharp.fluid} className={props.className} />)
export const Archive05 = i(props => <Img fluid={props.data.Archive05.childImageSharp.fluid} className={props.className} />)
export const Archive06 = i(props => <Img fluid={props.data.Archive06.childImageSharp.fluid} className={props.className} />)
export const Archive07 = i(props => <Img fluid={props.data.Archive07.childImageSharp.fluid} className={props.className} />)
export const Archive08 = i(props => <Img fluid={props.data.Archive08.childImageSharp.fluid} className={props.className} />)
export const Archive09 = i(props => <Img fluid={props.data.Archive09.childImageSharp.fluid} className={props.className} />)
export const Archive10 = i(props => <Img fluid={props.data.Archive10.childImageSharp.fluid} className={props.className} />)
export const Archive11 = i(props => <Img fluid={props.data.Archive11.childImageSharp.fluid} className={props.className} />)
export const Archive12 = i(props => <Img fluid={props.data.Archive12.childImageSharp.fluid} className={props.className} />)
export const Archive13 = i(props => <Img fluid={props.data.Archive13.childImageSharp.fluid} className={props.className} />)
export const Archive14 = i(props => <Img fluid={props.data.Archive14.childImageSharp.fluid} className={props.className} />)
export const Archive15 = i(props => <Img fluid={props.data.Archive15.childImageSharp.fluid} className={props.className} />)
export const Archive16 = i(props => <Img fluid={props.data.Archive16.childImageSharp.fluid} className={props.className} />)
